body {
  background: #fff;
  font-family: "Roboto", sans-serif;
}
.btn-primary {
  color: rgb(255, 255, 255);
  background-color: #118fff;
  border-color: #118fff;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover,
.btn-primary:focus {
  color: rgb(255, 255, 255);
  background-color: #1082e6;
  border-color: #1082e6;
}
.btn {
  padding: 8px 20px;
}
.loginform {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
}
.loginform:before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./assets/images/abg-min.jpg);
  background-size: cover;
  z-index: -1;
}
span.loading-table {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.836);
  color: #000;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
/* pages */
.headers {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  background-color: white;
}
.headers button.btn.btn-primary {
  border-radius: 30px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #3a3afb;
}
.menu {
  background-color: white;
  display: flex;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 1%;
}
.menupagination {
  display: flex;
  justify-content: space-between;
}
.pagination button:nth-child(1n + 10) {
  display: none;
}
.pagination button:last-child,
.pagination button:nth-last-child(2) {
  display: block;
}
.pagination button:nth-child(9) {
  margin-right: 30px;
  position: relative;
}

.pagination button:nth-child(9):after {
  content: "...";
  position: absolute;
  right: -30px;
  top: -2px;
  font-size: 24px;
}
.menu .dropdown {
  margin-left: 10px;
}
table {
  font-size: 14px;
}

.table > thead {
  font-weight: 700;
  font-size: 16px;
}

.table td,
.table th {
  padding: 10px;
  vertical-align: middle;
}
.table .action {
  font-size: 18px;
}
.table td img,
.table th img {
  object-fit: cover;
}
.table .action i {
  margin-right: 10px;
}
.table td:last-child,
.table th:last-child {
  padding-right: 0;
}

.table td:first-child,
.table th:first-child {
  padding-left: 0;
}

.table p {
  margin-bottom: 4px;
}

.pagination {
  margin-left: 5px;
}
button.active {
  background-color: #118fff !important;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
}
input {
  padding: 8px 20px;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #191919;
  width: 100%;
  min-width: 100px;
}
.pagination button {
  border: none;
  margin-left: 10px;
  border-radius: 6px;
  padding: 10px 20px;
}

.sidebar {
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 0;
  width: 300px;
  transition: all 0.3s;
  /* background-color: #099AAD; */
  background-image: url(./assets/images/sidebg.png);
  background-position: 10% 50%;
  background-size: cover;
}
.sidebar:after {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  /* background-color: #111111; */
  opacity: 0.9;
}
.sidebar ul.menu-pst {
  padding: 40px 20px;
  margin: 0;
  position: relative;
  z-index: 1;
}
.nav-toogle img {
  width: 30px;
  cursor: pointer;
  /* filter: invert(1); */
}
.logo {
  padding: 0 20px;
}
.logo img {
  width: 200px;
  margin-bottom: 20px;
}
/* .menu-list{
list-style-type: none;
line-height: 20px;
} */
.menu-item {
  width: 100%;
  position: relative;
  cursor: pointer;
  list-style: none;
  padding: 10px 20px;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s;
  margin-bottom: 2px;
}
.menu-item img {
  width: 22px;
  height: 22px;
}
.menu-item.active,
.menu-item:hover {
  color: rgb(15, 15, 15);
  background-color: #ffffff;
  border-radius: 6px;
}
/* .menu-item.active::before,.menu-item:hover::before {
  content: "";
  border-left: 6px solid rgb(255 0 0);
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  border-radius: 3px;
} */
.menu-item i {
  margin-right: 10px;
}
.toggle_icon {
  width: 30px;
  height: 30px;
}
.toggle_btn {
  margin-top: 25px;
  position: absolute;
}
.wrapper {
  margin: 90px 0 0 300px;
  padding: 40px;
  transition: all 0.3s;
  overflow: hidden;
}

.custom-navigation {
  padding: 8px 30px;
  /* transition: all .3s; */
  background-color: #e9f3ff;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  top: 0;
}
.custom-navigation .logo img {
  margin-bottom: 0;
}
.form-control,
.form-select {
  border: none;
  padding: 8px 20px;
  border-radius: 0;
  color: #000;
  font-weight: 500;
  background-color: transparent;
}
.form-control {
  height: auto;
  padding: 8px 20px;
  margin-bottom: 10px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #191919;
}

.form-control:focus {
  box-shadow: none;
}
.custom-navigation form {
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
}
.custom-navigation .nav .dropdown .btn {
  padding: 0 10px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  border: none !important;
}

.custom-navigation .nav .dropdown .btn img {
  margin-right: 10px;
}
.custom-navigation form input {
  border: 0;
  outline: none;
  box-shadow: 0;
}

.hide-sidebar .sidebar {
  left: -100%;
}

.hide-sidebar .wrapper {
  margin-left: 0;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.ratingbox {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid #118fff;
  box-shadow: 0px 3px 6px #00000014;
}

.ratingbox p {
  margin: 0;
}
.ratingbox .icon i {
  font-size: 24px;
}
.card {
  border-color: #118fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000014;
}
@media (max-width: 991px) {
  body.hide-sidebar {
    overflow: hidden;
  }
  .hide-sidebar:before {
    content: "";
    background-color: #0000008a;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
  .hide-sidebar .side-wrp {
    left: 0;
  }
}
.form-check-input:checked {
  background-color: #118fff;
  border-color: #118fff;
}
.form-switch .form-check-input {
  min-width: 40px;
  height: 20px;
}
.check {
  min-width: 20px;
  max-width: 20px;
}
.orderImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.custom-switch {
  margin-left: 18px;
}

.rmsc .dropdown-container {
  border: none !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0 !important;
}

/* Chat containers */
/* .container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
} */

/* Darker chat container */
.darker {
  border-color: #ccc;
  background-color: #ddd;
}

/* Clear floats */
/* .container::after {
  content: "";
  clear: both;
  display: table;
} */

/* Style images */
/* .container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}*/

/* Style the right image */
.container img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
} 

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}
.item-hover-pointer {
  cursor: pointer;
}
.text-center {
  text-align: "center";
}

.main__chatbody {
  flex-grow: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  border: 1px solid #118fff;
}

.no-chat-text {
  text-align: center;
  margin-top: 50px;
}
.centerLoader {
  display: flex;
  justify-content: center;
  padding: 3px;
}
